import Swal from "sweetalert2";

export const saError = (message) => {
  Swal.fire({
    title: "Oops...",
    text: message,
    icon: "error",
    confirmButtonClass: "btn btn-primary w-xs mt-2",
    reverseButtons: true,
    buttonsStyling: false,
    showCloseButton: true,
  });
}
export const saSuccess = (message) => {
  Swal.fire({title: message, icon: "success", showConfirmButton: false, timer: 1500});
}
export const formatPrice = (val) => {
  let formato = { minimumFractionDigits: 2 , style: 'currency', currency: 'BRL' }
  let value = val / 100;
  return value.toLocaleString('pt-BR', formato);
}
export const sumBusinessProductPrices = (products) => {
  if (!Array.isArray(products)) {
    return 0;
  }
  return products.reduce((prevVal, elem) => {
    return prevVal + ((elem.price * elem.quantity) * ((100 - elem.discount) / 100));
  }, 0);
}
export const nFormatter = (num, digits, currency) => {
  if (num > 0) {
    num = num /100;
  }
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup.slice().reverse().find(function (item) {
    return num >= item.value;
  });
  return item ? currency + ' ' + (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : currency + " 0";
}
export const getTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}
