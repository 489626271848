<script>
import appConfig from "../../../../app.config";
import { callUrl } from '@/helpers/api-apolo';
import {saError, saSuccess} from '@/helpers/global-functions';
import { overlay } from "@/state/helpers";
export default {
  page: {
    title: "Criar Senha",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      title: "Criar Senha",
      password: '',
      confirmPassword: '',
      token: '',
      email: ''
    };
  },
  name: "Usuários",
  methods: {
      ...overlay,
      validatePassword() {
          // passowrd match
          var confirm_password = document.getElementById("confirm-password-input");

          //Password validation

          if (this.password !== this.confirmPassword) {
              confirm_password.setCustomValidity("As senhas não são iguais");
          } else {
              confirm_password.setCustomValidity("");
              if (this.email !== '') {
                this.savePassword();
              }
          }
      },
      formValidation(val) {
        return ['form-control',{'is-invalid': !val || val === ''},{'is-valid': val && val !== ''}]
      },
      async savePassword() {
          try {
            this.changeShowOverlay({show: true});
            if (this.$route.params.email) {
              await callUrl({password: this.password}, '/user/' + this.email + '/recovery-password/' + this.token, 'POST', '', true)
                .then(async () => {
                  this.changeShowOverlay({show: false});
                  saSuccess("Senha alterada com Sucesso!")
                  await this.$router.push({path: '/login'});
                });
            } else {
              await callUrl({password: this.password, email: this.email}, '/user/validate/' + this.token, 'POST', '', true)
                  .then(async () => {
                    this.changeShowOverlay({show: false});
                    saSuccess("Senha criada com Sucesso!")
                    await this.$router.push({path: '/login'});
                  });
            }
          } catch (error) {
            this.changeShowOverlay({show: false});
            console.log(error.message)
            saError(error.message)
          }
      }
  },
  mounted() {
      if (this.$route.params.id) {
        this.token = this.$route.params.id;
      }
      if (this.$route.params.email) {
        this.email = this.$route.params.email;
      }
      var password = document.getElementById("password-input")
      password.onchange = this.validatePassword;
      document.querySelectorAll("form .auth-pass-inputgroup")
          .forEach(function (item) {
              item.querySelectorAll(".password-addon").forEach(function (subitem) {
                  subitem.addEventListener("click", function () {
                      var passwordInput = item.querySelector(".password-input");
                      if (passwordInput.type === "password") {
                          passwordInput.type = "text";
                      } else {
                          passwordInput.type = "password";
                      }
                  });
              });
          });



      var myInput = document.getElementById("password-input");
      var letter = document.getElementById("pass-lower");
      var capital = document.getElementById("pass-upper");
      var number = document.getElementById("pass-number");
      var length = document.getElementById("pass-length");

      // When the user clicks on the password field, show the message box
      myInput.onfocus = function () {
          document.getElementById("password-contain").style.display = "block";
      };

      // When the user clicks outside of the password field, hide the password-contain box
      myInput.onblur = function () {
          document.getElementById("password-contain").style.display = "none";
      };

      // When the user starts to type something inside the password field
      myInput.onkeyup = function () {
          // Validate lowercase letters
          var lowerCaseLetters = /[a-z]/g;
          if (myInput.value.match(lowerCaseLetters)) {
              letter.classList.remove("invalid");
              letter.classList.add("valid");
          } else {
              letter.classList.remove("valid");
              letter.classList.add("invalid");
          }

          // Validate capital letters
          var upperCaseLetters = /[A-Z]/g;
          if (myInput.value.match(upperCaseLetters)) {
              capital.classList.remove("invalid");
              capital.classList.add("valid");
          } else {
              capital.classList.remove("valid");
              capital.classList.add("invalid");
          }

          // Validate numbers
          var numbers = /[0-9]/g;
          if (myInput.value.match(numbers)) {
              number.classList.remove("invalid");
              number.classList.add("valid");
          } else {
              number.classList.remove("valid");
              number.classList.add("invalid");
          }

          // Validate length
          if (myInput.value.length >= 8) {
              length.classList.remove("invalid");
              length.classList.add("valid");
          } else {
              length.classList.remove("valid");
              length.classList.add("invalid");
          }
      };
  }
}
</script>

<template>
    <!-- auth-page wrapper -->
    <div class="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
        <div class="bg-overlay"></div>
        <!-- auth-page content -->
        <div class="auth-page-content overflow-hidden pt-lg-5">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card overflow-hidden">
                            <div class="row justify-content-center g-0">
                                <div class="col-lg-6">
                                    <div class="p-lg-5 p-4 auth-one-bg h-100">
                                        <div class="bg-overlay"></div>
                                        <div class="position-relative h-100 d-flex flex-column">
                                            <div class="mb-4">
                                                <a href="/" class="d-block">
                                                    <img src="@/assets/images/logo-light.png" alt="" height="120">
                                                </a>
                                            </div>
                                            <div class="mt-auto">
                                                <div class="mb-3">
                                                    <i class="ri-double-quotes-l display-4 text-success"></i>
                                                </div>

                                                <div id="qoutescarouselIndicators" class="carousel slide"
                                                    data-bs-ride="carousel">
                                                    <div class="carousel-indicators">
                                                        <button type="button" data-bs-target="#qoutescarouselIndicators"
                                                            data-bs-slide-to="0" class="active" aria-current="true"
                                                            aria-label="Slide 1"></button>
                                                        <button type="button" data-bs-target="#qoutescarouselIndicators"
                                                            data-bs-slide-to="1" aria-label="Slide 2"></button>
                                                        <button type="button" data-bs-target="#qoutescarouselIndicators"
                                                            data-bs-slide-to="2" aria-label="Slide 3"></button>
                                                    </div>
                                                    <div class="carousel-inner text-center text-white-50 pb-5">
                                                        <div class="carousel-item active">
                                                            <p class="fs-15 fst-italic">" Caso não fique satisfeito com o produto, oferecemos uma garantia de 30 dias para devolução."</p>
                                                        </div>
                                                        <div class="carousel-item">
                                                            <p class="fs-15 fst-italic">" O bom conteúdo é a melhor ferramenta de vendas do mundo.
                                                              <b>Marcus Sheridan</b>"</p>
                                                        </div>
                                                        <div class="carousel-item">
                                                            <p class="fs-15 fst-italic">" Apolo é a ferramenta de maior inovação no mercado! Agende agora uma avaliação sem compromisso!"</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- end carousel -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- end col -->

                                <div class="col-lg-6">
                                    <div class="p-lg-5 p-4">
                                        <h5 class="text-primary">Crie a sua senha</h5>

                                        <div class="p-2">
                                            <form>
                                                <div class="mb-3">
                                                    <label class="form-label" for="email-input">Email</label>
                                                    <div class="position-relative email-inputgroup">
                                                        <input type="email"
                                                            :class="formValidation(email)"
                                                            placeholder="Digite seu Email"
                                                            v-model="email"
                                                            id="email-input" aria-describedby="emailInput"
                                                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required>
                                                        <div class="invalid-feedback">Campo Obrigatório</div>
                                                        <div class="valid-feedback">Ok</div>
                                                    </div>
                                                </div>
                                                <div class="mb-3">
                                                    <label class="form-label" for="password-input">Senha</label>
                                                    <div class="position-relative auth-pass-inputgroup">
                                                        <input type="password" class="form-control pe-5 password-input"
                                                            placeholder="Digite sua senha"
                                                            v-model="password"
                                                            id="password-input" aria-describedby="passwordInput"
                                                            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" required>
                                                        <button
                                                            class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                                            type="button" id="password-addon"><i
                                                                class="ri-eye-fill align-middle"></i></button>
                                                    </div>
                                                    <div id="passwordInput" class="form-text">Precisa ter ao menos 8 carácteres.</div>
                                                </div>

                                                <div class="mb-3">
                                                    <label class="form-label" for="confirm-password-input">Confirme a Senha</label>
                                                    <div class="position-relative auth-pass-inputgroup mb-3">
                                                        <input type="password" class="form-control pe-5 password-input"
                                                            placeholder="Digite a confirmação"
                                                            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                                            v-model="confirmPassword"
                                                            id="confirm-password-input" required>
                                                        <button
                                                            class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                                            type="button" id="confirm-password-input"><i
                                                                class="ri-eye-fill align-middle"></i></button>
                                                    </div>
                                                </div>

                                                <div id="password-contain" class="p-3 bg-light mb-2 rounded">
                                                    <h5 class="fs-13">Senha precisa ter:</h5>
                                                    <p id="pass-length" class="invalid fs-12 mb-2">Mínimo de <b>8
                                                            carácteres</b></p>
                                                    <p id="pass-lower" class="invalid fs-12 mb-2">Letras <b>minúsculas</b>
                                                         (a-z)</p>
                                                    <p id="pass-upper" class="invalid fs-12 mb-2">Pelo menos 1 letra
                                                        <b>maiúscula</b> (A-Z)</p>
                                                    <p id="pass-number" class="invalid fs-12 mb-0">Ao menos 1 <b>número</b>
                                                        (0-9)</p>
                                                </div>

                                                <div class="mt-4">
                                                    <button class="btn btn-success w-100" @click.prevent="validatePassword"
                                                        type="submit">Criar Senha</button>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <!-- end col -->
                            </div>
                            <!-- end row -->
                        </div>
                        <!-- end card -->
                    </div>
                    <!-- end col -->

                </div>
                <!-- end row -->
            </div>
            <!-- end container -->
        </div>
        <!-- end auth page content -->

        <!-- footer -->
        <footer class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="text-center">
                            <p class="mb-0 text-muted">&copy; {{new Date().getFullYear()}} Apolo CRM. Criado com <i
                                    class="mdi mdi-heart text-danger"></i> por Apolo Group</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <!-- end Footer -->
    </div>
    <!-- end auth-page-wrapper -->
</template>
